/* You can add global styles to this file, and also import other style files */



/*  Overrides booststrap underlined link */
a {
  text-decoration:none;
}
a.mega-panel-anchor {
  text-decoration: underline;
}
