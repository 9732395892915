@font-face {
  font-family: GilroySemiBold;
  src: url("../font/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: GilroyRegular;
  src: url("../font/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: VAGRoundedThin;
  src: url("../font/vag_rounded_std-100-thin.woff2") format("woff2"),
    url("../font/vag_rounded_std-100-thin.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: VAGRoundedLight;
  src: url("../font/vag_rounded_std-300-light.woff2") format("woff2"),
    url("../font/vag_rounded_std-300-light.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'FoundrySterlingBook';
  src: url("../font/FoundrySterling-Book.otf") format("truetype");
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

html,
body {
  color: #f0f0f0;
  font-family: GilroyRegular, sans-serif;
  font-weight: normal;
  font-size: 19px;
  overflow-x: hidden;
  height: 100%;
}

a:hover {
  text-decoration: none;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-track img {
  height: 100% !important;
  object-fit: cover;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.content_btn {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.amc-btn {
  border: 1px solid #FF3700;
  padding: 23px 30px 23px 30px;
  color: #2a2a2a;
  /* letter-spacing: 1px; */
  min-width: 230px;
  font-family: GilroyRegular, sans-serif;
  font-size: 19px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  background: transparent;
  background-image: none;
  border-style: solid;
  z-index: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.amc-btn:hover {
  border-color: transparent;
  color: #fff;
}

.amc-btn::before {
  bottom: 0;
  content: "";
  display: block;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: left .25s ease-in-out, right .25s ease-in-out, width .25s ease-in-out;
  transition: left .25s ease-in-out, right .25s ease-in-out, width .25s ease-in-out;
  width: 0;
  z-index: -1;
  background: -webkit-gradient(linear, left top, right top, from(#840d81), to(#fe6b45));
  background: linear-gradient(90deg, #840d81, #fe6b45)
}

.amc-btn:hover::before {
  left: 0;
  right: auto;
  width: 100%
}

.amc-btn-light {
  border: 1px solid #fff;
  padding: 23px 30px 23px 30px;
  color: #fff;
  /* letter-spacing: 1px; */
  min-width: 230px;
  font-family: GilroyRegular, sans-serif;
  font-size: 19px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background: transparent;
}

.amc-btn-light-contact {
  min-width: 230px;
  font-family: GilroyRegular, sans-serif;
  border: 1px solid #fff;
  padding: 23px 30px 23px 30px;
  color: #fff;
  /* letter-spacing: 1px; */
  font-size: 19px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #ff3700;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background: transparent;
}

.amc-btn-light-contact img {
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.amc-btn-light:hover {
  border-color: transparent;
  color: #fff;
}

.amc-btn-light-contact:hover {
  border: 1px solid #ff3700;
  background: #ff3700;
  color: #fff;
  box-shadow: inset 400px 0 0 0 transparent;
}

.amc-btn-light::before {
  bottom: 0;
  content: "";
  display: block;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: left .25s ease-in-out, right .25s ease-in-out, width .25s ease-in-out;
  transition: left .25s ease-in-out, right .25s ease-in-out, width .25s ease-in-out;
  width: 0;
  z-index: -1;
  background: -webkit-gradient(linear, left top, right top, from(#840d81), to(#fe6b45));
  background: linear-gradient(90deg, #840d81, #fe6b45)
}

.amc-btn-light:hover::before {
  left: 0;
  right: auto;
  width: 100%
}

.amc-btn-light-contact:hover .white {
  transform: translate(10px);
}

.amc-btn-orange {
  border: 1px solid #FF3700;
  padding: 23px 30px 23px 30px;
  color: #2a2a2a;
  /* letter-spacing: 1px; */
  min-width: 230px;
  font-family: GilroyRegular, sans-serif;
  font-size: 19px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  background: transparent;
  background-image: none;
  border-style: solid;
  z-index: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.amc-btn img,
.amc-btn-light img {
  margin-left: 40px;
}

.icon_open_close {
  display: none;
}

.home {
  height: 100vh;
  width: 100%;
  top: 0;
  z-index: 1;
}


.sustainability .content_sustainability .content p {
  background-color: #70a489;
  color: white;
  display: inline;
}



.field_last_SF {
  background-color: #d8d8d8;
  height: 445px;
  padding: 0 80px;
  overflow: hidden;
  /* margin-bottom: 50px; */
}

.field_last_SF .title {
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  font-family: GilroyRegular, sans-serif;
  color: #2a2a2a;
}

.field_last_SF .image_LSF {
  height: 275px;
}

.field_last_SF .image_LSF img {
  object-fit: cover;
  width: 445px;
}

.field_last_SF .text-LSM {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 0 70px 0 150px;
  font-size: 24px;
}



.corusel_fields_second .carousel_2 .card {
  height: 485px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 0;
}

.corusel_fields_second .carousel_2 .card .imag_Caros_second {
  height: 50%;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.corusel_fields_second .carousel_2 .card .imag_Caros_second img {
  height: auto;
  width: auto;
  position: absolute;
  left: -100px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.corusel_fields_second .carousel_2 .card .Title h3::before {
  display: none;
}



.content_Why .slick-dots {
  padding: 0;
}

.content_Why .slick-dots li {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 10px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: #d3d3d3;
  transition: width 5s ease-out 0s;
}

.content_Why .slick-dots li:first-child {
  border-radius: 60px 0 0 60px;
  margin-left: 0;
}

.content_Why .slick-dots li:last-child {
  border-radius: 0 60px 60px 0;
  margin-right: 0;
}

.content_Why .slick-dots li:hover,
.content_Why .slick-dots li.slick-active {
  background: #557587;
}

.content_Why .slick-dots li button {
  display: none !important;
}

app-generic {
  background-color: #f3f2f3;
  display: block;
  height: fit-content;
}


.generic-content {
  /* background-color: #f3f2f3; */
  color: #000000;
  font-family: GilroyRegular, serif;
  font-size: 20px;
  background-color: #fff;
  /* padding: 0 36px; */
}

.generic-content .block {
  padding-bottom: 70px;
}

.generic-content .row {
  margin: 0;
  height: 100%;
}

.generic-content .content_child {
  margin: 20px 0;
}

.generic-content .content {
  /* width: 100%;*/
  padding: 47px 52px;
  background-color: #FFFFFF;
  /* overflow: auto; */
}

.generic-content .content h1 {
  font-family: GilroySemiBold, sans-serif;
  font-size: 37px;
  width: 100%;
}

.generic-content .content h2 {
  font-family: GilroySemiBold, sans-serif;
  font-size: 34px;
  width: 100%;
}

.generic-content .content h3 {
  font-family: GilroySemiBold, sans-serif;
  font-size: 28px;
  width: 100%;
}

.generic-content .content h4 {
  font-family: GilroySemiBold, sans-serif;
  font-size: 24px;
  width: 100%;
}

.generic-content .content p {
  font-family: GilroyRegular, sans-serif;
  margin-bottom: 0;
  width: 100%;
}

.details .text-align-left,
.content .text-align-left {
  text-align: left;
}

.details .text-align-justify,
.content .text-align-justify {
  text-align: justify;
}

.details .text-align-center,
.content .text-align-center {
  text-align: center;
}

.details .text-align-right,
.content .text-align-right {
  text-align: right;
}

.content * {
  max-width: 100%;
}

.newsroom-detail figcaption,
.content figcaption {
  font-size: 15px;
  margin-bottom: 12px;
  font-style: italic;
}

.details [data-align="left"],
.content [data-align="left"] {
  float: left;
  margin-right: 25px;
  text-align: left;
}

.details [data-align="right"],
.content [data-align="right"] {
  float: right;
  margin-left: 25px;
  text-align: right;
}

.details [data-align="center"],
.content [data-align="center"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.details img,
.content img {
  /* object-fit: cover; */
  /* padding-right: 40px; */
  margin-bottom: 12px;
}

.generic-content .content a {
  color: black;
  text-decoration: underline;
}

.generic-content .content a:hover {
  text-decoration: none;
}

/* .generic-content .content span.bg_orange {
  background-color: #FF3700;
  color: #FFFFFF;
} */

.generic-content .content ol,
.generic-content .content ul {
  padding-right: 100px;
  padding-left: 51px;
  padding-top: 26px;
}

.generic-content .content ol li,
.generic-content .content ul li {
  font-family: GilroyRegular, sans-serif;
  margin-bottom: 28px;
}

.generic-content .content ul li::before {
  font-family: 'FontAwesome', serif;
  font-weight: bold;
  font-size: 10px;
  content: "\f0c8";
  color: #FF3700;
  margin-right: 12px;
}

.generic-content .content ol li::before {
  content: counter(li)'.';
  color: #FF3700;
  font-family: GilroyRegular, serif;
  font-size: 20px;
  margin-right: 12px;
}

.generic-content .content ol li {
  counter-increment: li
}

.generic-content .generic-news-list {
  float: left;
  height: 100%;
}

.generic-content .video-container {
  float: left;
  height: 340px;
  padding: 0;
  position: relative;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.generic-content .video-container .video iframe {
  width: 100%;
  height: 100%;
}

.generic-content .video-container .mask {
  /* background-color: rgb(92, 127, 147); */
  background-color: #0072ce;
  height: 100%;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  width: 100%;
}

/* .generic-content .video-container .mask .fa {
  font-size: 60px;
  color: #ffffff;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 25px);
} */

.generic-content .video-container .mask img {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 25px);
}

.generic-content .table {
  background-color: #FFFFFF;
  border: 0 none;
  color: #212529;
  margin-bottom: 1rem;
  padding: 47px 52px;
  width: 100%;
  min-width: 800px;
}

.generic-content .table td,
.generic-content .table th {
  padding: 1rem !important;
}

.generic-content .table thead th {
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  border-left: 0;
  border-right: 0;
  /* background-color: #e6dde3; */
  font-family: GilroySemiBold;
}

.generic-content .table tbody td {
  border: 2px solid #000000;
  border-left: 0;
  border-right: 0;
  font-family: GilroyRegular, sans-serif;
}

.generic-bg-color-text .content a {
  color: #FFFFFF;
  text-decoration: underline;
}

.generic-bg-color-text .content a:hover {
  text-decoration: none;
}

.generic-facts {
  /*! add style  */
  background-size: cover;
  background-position: bottom;
}

.generic-facts .fact {
  /*! change margin  */
  background-image: url("../img/icon/icon_fact.svg");
  background-position: center;
  background-repeat: no-repeat;
  font-family: GilroyRegular, serif;
  color: #FFFFFF;
  text-align: center;
  margin: 100px 0;
  height: 155px;
}

.generic-facts .fact h2 {
  font-size: 46px;
  margin: 0;
  padding-top: 38px;
}

.generic-facts .fact p {
  font-size: 24px;
  margin: 0;
}

/* .generic-carousel .controls-div {
  display: flex;
  justify-content: space-between;
  position: absolute;
  flex-direction: column;
  left: 700px;
  bottom: 50px;
  left: calc(100% - 55vw);
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.generic-carousel .slick-list,
.generic-carousel .slick-track {
  height: 100%;
}

.generic-carousel .slick-initialized .slick-slide {
  margin: 0 1px;
  overflow: hidden;
}

.generic-carousel .slick-initialized .slick-slide img {
  transition: transform .2s;
  /* Animation */
}

.generic-carousel .slick-initialized .slick-slide img:hover {
  transform: scale(1.5);
}

.generic-carousel .slick-prev,
.generic-carousel .slick-next {
  /*! chnage z-index  */
  background-color: rgba(0, 114, 206, 0.36);
  z-index: 88;
  height: 100%;
  width: 52px;
}

.generic-carousel .slick-prev:hover,
.generic-carousel .slick-next:hover {
  background-color: rgb(0 114 206);
}

.generic-carousel .slick-prev {
  left: 30px;
}

.generic-carousel .slick-prev:before {
  content: url("../img/icon/slider-arrow-left.png");
  opacity: 1;
}

.generic-carousel .slick-next {
  right: 30px;
}

.generic-carousel .slick-next:before {
  content: url("../img/icon/slider-arrow-right.png");
  opacity: 1;
}


.newsroom-detail {
  background-color: #fff;
  color: #343434;
  font-family: GilroyRegular, serif;
  font-size: 20px;
  padding-bottom: 56px;
}

.newsroom-detail .summary {
  padding: 52px 40px;
  background-color: #fff;
}

.newsroom-detail .summary .summary-title {
  color: #343434;
  font-size: 37px;
}

.newsroom-detail .details {
  padding: 52px 40px;
  background-color: #FFFFFF;
  display: inline-block;
}

.newsroom-detail .details h2 {
  font-size: 37px;
}

.newsroom-detail .details p {
  font-family: GilroyRegular, sans-serif;
}

.newsroom-detail .details a {
  color: #FF3700;
  text-decoration: underline;
}

.newsroom-detail .details a:hover {
  color: #343434;
}

.newsroom-detail .details ol,
.newsroom-detail .details ul {
  margin-top: 20px;
  padding-right: 100px;
}

.newsroom-detail .details ul li::before {
  font-family: 'FontAwesome', serif;
  font-weight: bold;
  font-size: 8px;
  content: "\f0c8";
  color: #FF3700;
  margin-right: 12px;
}

.newsroom-detail .details ol li::before {
  content: counter(li)'.';
  color: #FF3700;
  font-family: GilroyRegular, serif;
  font-size: 20px;
  margin-right: 12px;
}

.newsroom-detail .details ol li {
  counter-increment: li
}

.app-references .slick-initialized {
  display: grid;
  grid-template-columns: 3fr 3fr auto auto auto 3fr 3fr;
  grid-template-rows: 3fr 3fr 1fr;
  align-items: center;
}

.app-references .slick-arrow {
  z-index: 999;
  opacity: 1;
}

.app-references .slick-prev,
.app-references .slick-next {
  top: unset;
  transform: translate(0, 0);
}

.app-references .slick-prev {
  /* left: 88px; */
  /* left: 0;
  bottom: 0; */
  left: -15px;
  bottom: 34px;
  position: relative;
  grid-column: 3;
  grid-row: 3;
}

.app-references .slick-prev:before {
  content: url("../img/icon/arrow-left.png");
}

.app-references .slick-list {
  grid-area: 1 / 1 / 4 / 8;
}

.app-references .slick-next {
  /* right: 88px; */
  /* right: 0;
  bottom: 0; */
  right: -5px;
  bottom: 34px;
  position: relative;
  grid-column: 5;
  grid-row: 3;
}

.app-references .slick-next:before {
  content: url("../img/icon/arrow-right.png");
}

.app-references .slick-dots {
  /* bottom: 65px; */
  bottom: 0 !important;
  position: relative;
  grid-column: 4;
  grid-row: 3;
  top: -30px;
}

.app-references .slick-dots li {
  margin: 0 10px;
}

.app-references .slick-dots li button {
  background: black;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
}

.app-references .slick-dots li button:before {
  font-size: 0px;
  color: black;
  width: 34px;
  height: 34px;
  /* opacity: 1; */
}

.app-references .slick-dots li.slick-active button:before {
  color: transparent;
  border: 1px solid #ff3700;
  border-radius: 100%;
  transform: translate(-12px, -12px);
  opacity: 1;
}

.reference-details .attributes .application li {
  list-style-type: square;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.reference-details .attributes .application li::marker {
  margin-right: 6px;
}


.cookie-policy h2,
.cookie-policy h3 {
  font-size: 21px;
  margin-top: 0;
}

.cookie-policy p {
  margin-bottom: 25px;
}

.cookie-policy .cookie-cta {
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 21px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 30px;
}

.cookie-policy a {
  color: #FFFFFF;
}

.cookie-policy a:hover,
.cookie-policy .cookie-cta:hover {
  text-decoration: underline;
}

.amc-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background: #fff url("../img/icon/arrow-select.png") right 15px center/24px 15px no-repeat; */
  background: #fff url("../img/icon/arrow-select.png") right 15px center/24px 15px no-repeat;
}


@-webkit-keyframes show {
  0% {
    height: 0;
  }

  100% {
    height: 120px;
  }
}

@-moz-keyframes show {
  0% {
    height: 0;
  }

  100% {
    height: 120px;
  }
}

@-o-keyframes show {
  0% {
    height: 0;
  }

  100% {
    height: 120px;
  }
}

@keyframes show {
  0% {
    height: 0;
  }

  100% {
    height: 120px;
  }
}

@-webkit-keyframes height-card-body {
  0% {
    height: 150px;
  }

  100% {
    height: 270px;
  }
}

@-moz-keyframes height-card-body {
  0% {
    height: 150px;
  }

  100% {
    height: 270px;
  }
}

@-o-keyframes height-card-body {
  0% {
    height: 150px;
  }

  100% {
    height: 270px;
  }
}

@keyframes height-card-body {
  0% {
    height: 150px;
  }

  100% {
    height: 270px;
  }
}

@-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
}

@-moz-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
}

@-o-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    opacity: 1;
  }
}
